<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <div class="card">
          <h2 class="card-title text-center">
            <a href="http://opensnippets.com">Continue with your Profile</a>
          </h2>
          <div class="card-body py-md-4">
            <form _lpchecked="1">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  v-model="emailaddress"
                  id="email"
                  placeholder="Email"
                />
              </div>

              <div class="form-group">
                <input
                  type="password"
                  v-model="password"
                  class="form-control"
                  id="password"
                  placeholder="Password"
                />
              </div>

              <div
                class="d-flex flex-row align-items-center justify-content-center"
              >
                <button
                  @click.prevent="merchantOnboardingSign"
                  class="btn btn-primary"
                >
                  <b-spinner v-if="loading" type="grow"></b-spinner>
                  <span v-else>Sign In To complete profile</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      emailaddress: "",
      loading: false
    };
  },

  methods: {
    merchantOnboardingSign() {
      const payload = {
        emailaddress: this.emailaddress,
        password: this.password
      };

      this.loading = true;
      this.$store
        .dispatch("crowdhubaccount/merchantOnboardingSign", payload)
        .then((response) => {
          if (response.status == 200) {
          //   this.$swal({
          //   icon: "success",
          //   title: "success",
          //   text: "You have successfully login"
          // });
            this.loading = false;
            localStorage.setItem("handshakeGUID", response.data.guid);
            this.$router.push("/continue-business-profile");
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=PT+Sans");


h2 {
  padding-top: 1.5rem;
}
a {
  color: #333;
}
a:hover {
  color: #da5767;
  text-decoration: none;
}
.card {
  border: 0.4rem solid #f8f9fa;
  top: 10%;
}
.form-control {
  background-color: #f8f9fa;
  padding: 20px;
  padding: 25px 15px;
  margin-bottom: 1.3rem;
}

.form-control:focus {
  color: #000000;
  background-color: #ffffff;
  border: 3px solid #da5767;
  outline: 0;
  box-shadow: none;
}

.btn {
  padding: 0.6rem 1.2rem;
  background: #da5767;
  border: 2px solid #da5767;
}
.btn-primary:hover {
  background-color: #df8c96;
  border-color: #df8c96;
  transition: 0.3s;
}
</style>
